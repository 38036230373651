<template>
    <div>
        <!-- App Header -->
        <div class="appHeader no-border transparent position-absolute">
            <div class="left">
                <router-link :to="{name: 'Contact'}">
                    Contact
                </router-link>
<!--                <a href="javascript:;" class="headerButton goBack">-->
<!--                    <ion-icon name="chevron-back-outline"></ion-icon>-->
<!--                </a>-->
            </div>
            <div class="pageTitle"></div>
            <div class="right">
                <router-link v-if="$route.name==='Register'" :to="{name: 'Auth'}" class="headerButton">
                    Login
                </router-link>
                <router-link v-if="$route.name==='Auth'" :to="{name: 'Register'}" class="headerButton">
                    Register
                </router-link>
            </div>
        </div>
        <!-- * App Header -->

        <!-- App Capsule -->
        <div id="appCapsule">
            <router-view/>
        </div>
    </div>
</template>

<script>
import firebase from "../../configs/firebase";

export default {
    name: "container",
    beforeRouteEnter(to, from, next) {
        if(firebase.auth().currentUser){
            return next({name: 'MetricsWallet'})
        }
        next()
    },
    mounted() {
        // this.$notifySpam()
    }
}
</script>

<style scoped>

</style>